@import '../var.scss';

.usp-banner {
    text-align: center;
    padding: 20px 0;

    > h4 {
        margin-bottom: 20px;
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;
        @media only screen and (min-width: 769px) {
            justify-content: space-evenly;
        }
        li {
            flex: 1 0 auto;
            flex-basis: 22%;
            flex-grow: 0;
            max-width: 60px;
            margin-bottom: 20px;
            margin: 0 1%;
            cursor: pointer;
            div {
                background-color: $red;
                padding: 7px 0 0;
                border: 2px solid $red;
                border-radius: 50%;
                transition: ease all .2s;
                &:before {
                    content:'';
                    float:left;
                    padding-top:100%;
                }
                &:hover, &:focus {
                    background-color: white;
                    
                    h5, h3 {
                        display: block;
                    }
                    svg {
                        color: $red;
                    }
                }
            }

            svg {
                font-size: 37px;
                margin-bottom: 10px;
                color: #ffffff;
                @media only screen and (max-width: 649px) {
                    font-size: 30px;
                }
            }

            h5, h3 {
                font-size: .8em;
                display: none;
                position: absolute;
                top: 110%;
                left: 0; right: 0;
                text-align: center;
                padding: 14px 12px;
                background-color: $red;
                color: white;
                z-index: 999;
                font-size: 1.2em;
                line-height: 1.4em;
                letter-spacing: .05em;
                transition: ease all .2s;
            }
        }
    }
}