body { font-family: Roboto,Arial,Helvetica,sans-serif; font-weight: 400; color: #5f6368; font-size: 14px; }
h1, h2, h3, h4, h5, h6 { color: #202124; }
a:hover { text-decoration: none; }
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button { opacity: 1; }
.rel { position: relative; }
.account-nav {
	/* flex: 3; */
	display: inline-block;
	vertical-align: bottom;
	width: calc(100% / 6);
}
.account-nav a.list-group-item {
	display: block;
	width: 100%;
} 

.list-group-flush#profile {
	position: absolute;
	top: 100%; right: 0;
	width: 160px;
	z-index: 99;
}

#root {
	background-color: #fff;
}

.navigation {
	padding-right: 0;
	vertical-align: bottom;
}
/* .nav-sidebar .list-group-item { 
	padding: .50rem 1.25rem; 
	display: inline-block;
	width: calc(100% / 7);
	vertical-align: bottom;
} */
img { max-width: 100%; height: auto }
.navbar-brand img { max-width: 240px; height: auto; }
.nav-sidebar .list-group-item-action { font-weight: bold; color: rgba(0,0,0,.6); } 
.nav-sidebar .list-group-item-action.active, .btn-purple { color: rgba(39,49,108,.97); } 
/* .nav-sidebar .list-group-item-action.active + #profile { display: block; } */
.btn-red { color: rgba(255,255,255,.87); background-color: #e71837 !important; }

.card { -webkit-box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12); box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12); border-radius: 4px; }

.btn, .form-control { font-size: 14px; }
.form-control { border-radius: 0; }
/* .btn { border-radius: 0 !important; } */
.btn:not([disabled]):not(.disabled) { cursor: pointer; }

.btn-ticket {
	background-color: #e71837;
	border-color: #e71837;
	color: #fff;
	/* margin: 6px auto; */
}

a {
	color: #e71837;
}
.f-13 { font-size: 13px; }
.f-15 { font-size: 15px; }

.bg-purple { color: rgba(98,0,238,.87); background-color: #ede1fd; } 
.bg-blue { color: rgba(255,255,255,.87); background-color: #27316c; } 
.bg-red { color: rgba(255,255,255,.87); background-color: #e71837; } 

.ck-editor__editable { min-height: 300px !important; }

@media only screen and (max-width: 768px) {
	.form-control.w-25, .form-control.w-50, .form-control.w-75, .input-group.w-25, .media.w-50, .alert.w-50 { width: 100% !important; }
	.brand-container .navbar { margin: 0; padding: 0; }
	.brand-container .navbar-brand { padding: 5px 0; }
	.brand-img { height: 50px; }
	.brand-text { font-size: 14px; }

	.res-tbl { border: 0; border-collapse: collapse;  }
	.res-tbl, .res-tbl thead, .res-tbl tbody, .res-tbl th, .res-tbl td, .res-tbl tr { display: block;  }
	.res-tbl thead tr { position: absolute; top: -9999px; left: -9999px; overflow: hidden;  }
	.res-tbl tbody tr { margin: 0 0 15px 0; display: flex; flex-flow: column; }
	.res-tbl tr td { border-top: none; border-left: none; border-right: none; border-bottom: 1px solid #efefef; position: relative; padding-left: 40%; word-wrap: break-word; background: #fff; }
	.res-tbl tr td:last-child { border-bottom: none;  }
	​.res-tbl tr td:first-child { border-top: 1px solid #ddd;  }
	.res-tbl td:before { position: absolute; top: 6px; left: 6px; width: 55%; padding-right: 10px; white-space: nowrap; content: attr(data-label); text-align: left;  }
}
.hide {display: none !important;}

/* .navigation { 
	border: 1px solid rgb(212, 20, 20); 
	list-style: none; 
	margin: 0; 
	padding: 0; 
	display: flex; 
}
.navigation > a {
	flex: 3; 
	display: inline-block;
	width: auto;
}

@media all and (max-width: 480px) { 
	.navigation li { flex-basis: 100%; } 
} */
.list-group-item.active {
	background-color: initial;
	border: #27316c !important;
}
.icon-menu-item {
	max-width: 110px;
}
.perticket {
	font-size: .7em;
}
.stock-left {
	color: #e71837;	
}
.stock-left span {
	font-size: .7em;
	color: #5f6368;
}
.quantity-input {
display: flex;
justify-content: center;
align-items: center;
box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
border-radius: 3px;
}
.quantity-input:focus {
background: red;
}

.quantity-input__modifier,
.quantity-input__screen {
-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
		user-select: none;
outline: none;
}

.quantity-input__modifier {
padding: 0.7rem;
width: 3rem;
font-size: 1.5rem;
background: #f3f3f3;
color: #888;
border: 0 solid #dbdbdb;
text-align: center;
text-shadow: 0 1px 0 rgba(255, 255, 255, 0.6);
cursor: pointer;
}
.quantity-input__modifier:hover {
background: #dadada;
color: #555555;
}
.quantity-input__modifier--left {
border-radius: 3px 0 0 3px;
}
.quantity-input__modifier--right {
border-radius: 0 3px 3px 0;
}

.quantity-input__screen {
width: 4rem;
padding: 0.7rem;
font-size: 1.5rem;
border: 0;
border-top: 0 solid #dbdbdb;
border-bottom: 0 solid #dbdbdb;
text-align: center;
}

body {
/* width: 100vw;
height: 100vh; */
display: flex;
justify-content: center;
align-items: center;
text-align: center;
background: #e2e1e0;
color: #333;
font-family: "Roboto", sans-serif;
font-weight: 300;
font-size: 1rem;
}


footer {
	padding: 0 2.5% 20px !important;
	text-align: left;
	background-color: #e2e1e0;
	width: 100%;
}

footer a.logo {
	max-width: 320px;
	display: inline-block;
	vertical-align: top;
	margin-bottom: 60px;
	padding: .75rem 1.25rem;
}
/* footer #navAccordion {
	text-align: left !important;
}
footer #navAccordion .account-nav {
	width: 100%;
	max-width: 75%;
}
footer #navAccordion a.list-group-item {
	text-align: left !important;
	background-color: transparent !important;
	min-width: 110px;
	width: auto;
	vertical-align: top;
	display: inline-block;
	max-width: 100%;
}
footer #navAccordion div.icon-menu-item > a:first-of-type {
	display: none;
}
footer #navAccordion div.icon-menu-item a i {
	display: none;
}
footer #navAccordion a {
	color: #495057;
	/* text-align: center; */
/* } */
/* footer #navAccordion .list-group-flush#profile {
	position: static;
	display: block;
	width: auto;
}   */
.Stripe label {
	width: 100%;
}

.loading {
	position: fixed;
	top: 0; left: 0; bottom: 0; right: 0;
	width: 100%; height: 100%;
	z-index: 9;
	background-color: rgba(0,0,0,.5);
	text-align: center;
	padding-top: 40%;
	color: #fff;
	font-weight: 700;
	font-size: 24px;
}

