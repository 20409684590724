@import '../var.scss';

// .form-group {
//     position: relative;
//     input, textarea {
//         font-size: 14px;
//         background-color: transparent;
//         border: 2px solid #DFE1E6;
//         -moz-box-sizing: border-box;
//         box-sizing: border-box;
//         border-radius: 3px;
//         height: 44px;
//         position: relative;
//         z-index: 2;
//         -webkit-transition: background-color .2s ease-in-out 0s,border-color .2s ease-in-out 0s;
//         transition: background-color .2s ease-in-out 0s,border-color .2s ease-in-out 0s;
//     }

//     label {
//         position: absolute;
//         top: -20px;
//         left: 5px;
//         transition: ease all .2s;
//         font-size: 11px;
//     }

//     input, textarea {
//         margin-top: 30px;

//         &:not(:focus) {
//             + label {
//                 position: absolute;
//                 left: 10px;
//                 top: 10px;
//                 font-size: 1rem;
//              }
//         }
//     }
// }

// button[type="submit"] {
//     background: #fff;
//     color: rgba(0,0,0,0.54);
//     box-shadow: rgba(0,0,0,0.2) 1px 1px 5px 0;
//     border-color: transparent;
//     text-align: center;
//     border-radius: 3px !important   ;
//     width: 99%;
//     height: 40px;
//     margin-bottom: 0;
//     -webkit-transition: background-color .2s ease-in-out 0s,border-color .2s ease-in-out 0s;
//     transition: background-color .2s ease-in-out 0s,border-color .2s ease-in-out 0s;
// }

input[type=checkbox] {
    position: relative;
      cursor: pointer;
}
input[type=checkbox]:before {
    content: "";
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background-color:#e9e9e9;
}
input[type=checkbox]:checked:before {
    content: "";
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background-color:#1E80EF;
}
input[type=checkbox]:checked:after {
    content: "";
    display: block;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 2px;
    left: 6px;
}

button.btn-atb {
    &:disabled,
    [disabled] {
        background-color: transparent;
        border: 2px solid $blue;
        color: $blue;
    }

    a {
        color: #fff;
    }
}

p > a:not(.btn) {
    text-decoration: underline;
    // font-weight: ;
}


form.social-logins {
    margin: 10px auto;
    text-align: center;
    display: inline-block;
    width: calc(100% / 2 -20px);
    vertical-align: middle;
    margin: 10px !important;
    border: 2px solid $blue !important;
    padding: 12px;
    button {
        color: $blue !important;
        font-size: 1.2em;
        font-weight: 700;

        svg {
            display: inline-block;
            vertical-align: middle;
            max-width: 65px;
        }

    }

}

.accept-terms {
    .d-inline-block {
        vertical-align: top;
    }
    p {
        width: calc(100% - 45px);
        margin-left: 16px;
    }
}


.question-signin {
    button {
        display: inline-block;
        width: 47.5%;
        &:first-of-type {
            margin-right: 4.9%;
        }
    }
}