@import '../var';

@keyframes float-btt-in-c900f84e-a91b-49d8-a0fc-eebb28812a91 {
    0% {
        animation-timing-function: cubic-bezier(0.2242,0.7499,0.3142,0.8148);
        transform: translate(0px,47.76px) rotate(0deg) scale(1,1) skewX(0deg) skewY(0deg);
        opacity: 0.05;
    }
    100% {
        transform: translate(0px,0px) rotate(0deg) scale(1,1) skewX(0deg) skewY(0deg);
        opacity: 1;
    }
}


#out-of-stock {
    position: relative;
    .out-of-stock-banner {
        position: relative;
        top: 0; left: 0; right: 0;
        z-index: 9;
        background-color: $red;
        padding: 8px 14px;
        border-radius: 4px 4px 0 0;
        p {
            margin: 0;
            color: #fff;
        }
    }

    button.btn-atb {
        background-color: transparent;
        color: $blue;
        border-color: $blue;
    }
}


.trustpilot-widget {
    color: #fff !important;
}

.stock-left {
    font-weight: 700;
    &.green {
        color: $green;
        
    }
    &.red {
        color: $danger;
        
    }
    &.amber {
        color: $amber;
    }
}

.categories {
    > div {
        overflow-x: auto;
        white-space: nowrap;
        display: block;
        max-width: 95%;
        margin: auto;
        position: relative;
        &::-webkit-scrollbar {
            height: 4px;
        }
        
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }
        
        &::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 1px solid slategrey;
            border-radius: 4px;
        }
    }
    button {
        appearance: none;
        padding: 0;
        border: none;
        margin: 4px;
        padding: 4px 30px;
        border: 2px solid $blue;
        color: $blue;
        background-color: #fff;
        display: inline-block;
        transition: ease all .2s;
        outline: none;
        &:active, &:focus {
            background-color: $blue;
            color: #fff;
            outline: none;
        }
    }
}


.card {
    p {
        color: #222;
        padding: 0 5px;
        &.stock-left {
            font-size: 1.2em;
        }
    }
    p.urgent-message {
        border: 2px solid transparent;
        transition: ease all .2s;
        padding: 10px;
        font-size: .95em;
    }
    &.green {
        p.urgent-message {
            border-color: $green;
        }
    }
    &.red {
        p.urgent-message {
            border-color: $danger;
        }
    }

    button.btn-atb {
        margin-bottom: 5px !important; 
    }

    &#highlight {
        border-color: transparent;
        border-radius: 0 0 4px 4px;
        .card-body {
            padding-bottom: 0;
        }

        .card-title {
            // font-size: 17px;
            &:after {
                background-color: $goldDark;
            }
        }

        // box-shadow: 0 2px 1px -1px rgba(231,24,55, .2);
        // box-shadow: 0 2px 1px -1px rgba(232,185,35, .4);

        // box-shadow: 0 19px 38px rgba(232,185,35,0.30), 0 15px 12px rgba(232,185,35,0.22);
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
        // // box-shadow: 0 10px 20px rgba(197,165,0,0.19), 0 6px 6px rgba(197,165,0,0.23);
        border: 3px solid $goldDark;
        
        
        .urgent-message {
            // display: none;
        }

        span.perticket {
            background-color: $goldDark;
            padding: 4px 12px;
            font-size: .75em;
            font-weight: 700;
        }

        .end-date p {
            background-color: transparent;
            color: $red !important;
            font-weight: 700;
            margin-bottom: 0;
            border-bottom: 2px solid $red;
            display: inline-block;
        }
        button.btn-atb {
            border-color: $goldDark;
            background-color: $goldDark;
            &:hover {
                background-color: transparent;
                color: $goldDark;
            }
        }

    }
}
.highlight-banner {
    background-color: $goldDark;
    color: #fff;
    border-radius: 4px 4px 0 0;
    padding: 0 10px;
    p {
        margin: 0;
        font-size: 18px;
        text-transform: uppercase;
        line-height: 26px;
        padding-top: 3px;
    }
}

#out-of-stock .highlight-banner {
    background-color: white;
    color: #222;
}

// Title
.card-title {
    font-weight: 700;
    position: relative;
    line-height: 1.5em;
    // min-height: 70px;
    &:after {
        content: '';
        position: absolute;
        bottom: -6px;
        left: 0; right: 0;
        margin: auto;
        height: 2px;
        background-color: lighten($blue, 20);
        width: 50%;
    }

}


// Image
.card-img-top {
    // min-height: 220px;
    // display: table;
    // width: 100%;
    img {
        max-height: 100% !important;
    }

    &.equalHeight {
        height: auto !important;
    }
}


// Tickt
.perticket {
    background-color: $red;
    color: #fff;
    display: inline-block;
    padding: 4px 7px;
    clear: both;
    font-weight: 700;
}

.stock-left {
    display: block !important;
    margin: 10px auto;
    sup {
        top: auto;
        font-size: 1em;
        display: none;
    }
}


@-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
  @keyframes rotating {
    0% {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    20% {
      -ms-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    80% {
        -ms-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    100% {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
  }
  .rotating {
    -webkit-animation: rotating 6s linear infinite;
    -moz-animation: rotating 6s linear infinite;
    -ms-animation: rotating 6s linear infinite;
    -o-animation: rotating 6s linear infinite;
    animation: rotating 6s linear infinite;
    // animation-timing-function: cubic-bezier(.14, .75, .2, 1.01);

  }

.hourglass {
    // animation-name: flip;
    animation-timing-function: ease-in-out;
    // animation-duration: 2.5s;
    animation-delay: 5s;
    // animation-iteration-count: infinite;
    // transition: none !important;
    // display: inline-block;
    margin-left: 6px;
    display: none;
}

.page-home {
    .hourglass {
        display: inline-block;
    }
}
.row {
    display: flex;
    flex-wrap: wrap;
    
    .highlight-banner {
        p {
            font-weight: 700;
            letter-spacing: .05em;
        }
    }
    
    @media only screen and (max-width: 649px) {
        .highlight-product {
            .card-body {
                padding-top: 5px;
    
            }
            .card-body .lead {
                text-align: left;
                p.stock-left sup {
                    display: inline-block;
                }
            }
            .perticket span {
                display: inline-block;
            }
            // div.end-date {
            //     display: none;
            // }
        }
    }
}

.flex-match {
    flex: 1;
    .card-body {
        min-height: 360px;
        padding: 1.25rem 0;
    }
    .winner-chosen {
        .perticket {display: none}
    }
}

.donations-banner {
    background-color: $red;
    h5 {
        color: #fff;
        margin: 0;
        // text-transform: lowercase;
        padding: 12px 2.5%;
        // font-size: 18px;
    }
}

@media only screen and (max-width: 679px) {
    .flex-match {
        .card-body {
            min-height: auto;
            padding: 10px 0 20px 0;
            > .lead {
                // text-align: left;
            }
            p.stock-left {
                margin: 0 !important;
                font-size: 16px;
                display: inline-block !important;
                sup {
                    display: none;
                }
            }
        }
    }
    .card-title {
        font-size: 14px;
        line-height: 20px;
        text-transform: uppercase;
        // text-align: left;
        margin-bottom: 0;

        &:after {
            display: none;
        }
    }
    .perticket {
        // background-color: transparent !important;
        // color: #000;
        // text-align: left;
        padding-left: .5rem!important;
        font-weight: 700;
        margin-top: 10px;
        span {
            display: none;
        }
    }
    .urgent-message {
        display: none;
    }

    .btn-atb {
        padding: 6px 2.5%;
        font-size: 16px;
    }
    button.btn-atb {
        padding: 6px 2.5% !important;
    }
    .card-img-top {
        padding-top: 10px;
    }
    div.end-date {
        margin-bottom: 10px !important;
        p {
            font-size: 14px;
            // color: #000 !important;
            // background-color: transparent !important;
            margin-bottom: 0 !important;
        }
    }
}

@media only screen and (max-width: 359px) {
    .collections-list > .col-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}