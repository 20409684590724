.terms {
    ul, ol {
        list-style-type: none;
    }

    ul.no-pad {
        padding-left: 0;
        margin-left: 0;
    }

    ul > li > ul {
        padding-left: 5%;
    }
    ol li ol     {
        padding-left: 5%;
    }

    li {
        margin-bottom: 7px;
    }

    .terms-text > div {
        margin-bottom: 15px;
    }

    .terms-text > ol {
        padding-left: 0;
    }

        
}