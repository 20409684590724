@import '../var.scss';

.end-date {
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    p {
        margin-bottom: 10px;
        padding: 5px !important;
        background-color: $red;
        letter-spacing: .035em;
        color: #fff !important;
        font-weight: 700;
        span {
            font-weight: 700;
        }
    }
}