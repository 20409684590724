.discount-form {
    background-color: #fff;
    padding: 20px;
    margin-top: 10px;
    label {
        display: block;
    }
    input {
        padding: 20px 18px;            
        &[type="submit"] {
            margin-top: 15px;
        }
    }

}