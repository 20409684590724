@import '../var.scss';


.btn-atb {
    display: block;
    background-color: $blue;
    color: #fff;
    width: 100%;
    padding: 8px 2.5% !important;
    color: #fff;
    border: 2px solid transparent;
    transition: ease-in-out all .2s;
    outline: none;
    letter-spacing: .1em;
    text-transform: uppercase;
    font-size: 1.2em;
    font-weight: 700;
    margin-bottom: 10px;
    border-radius: 3px !important;
    &:focus {
        outline: none;
    }
    &:hover {
        border-color: $blue;
        background-color: #fff;
        color: $blue;
    }
}   

.update-product {
    text-align: left;

    .form-group {
        margin-bottom: 16px;
    }
    label, input {
        vertical-align: middle;
        display: inline-block;
        margin: 0;
    }
    label {
        margin-right: 25px;
    }
    .ck-editor {
        max-height: 420px;
        overflow-y: auto;
        box-shadow: 0 4px 2px -2px gray;
        margin-bottom: 35px;
    }
}

.trustpilot {
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.btn-sold-out {
    background-color: $red;
    opacity: .75;
    &:hover {
        background-color: $red;
        border-color: $red;
        color: #fff;
    }
}

.card-img-top {
    img {
        max-height: 300px;
    }
}

.card-body {
    .text-secondary {
        color: #222 !important;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            bottom: -6px;
            left: 0; right: 0;
            margin: auto;
            height: 2px;
            max-width: 120px;
            background-color: $blue;
        }
    }
    h4 {
        span {
            &.stock-green {
                color: $green;
            }
            &.stock-amber {
                color: $amber;
            }
            &.stock-red {
                color: $danger;
            }
        }

        sup {
            top: auto;
        }
    }

    
}

.btn-ticket {
    position: absolute;
    top: 0; right: 0;
    z-index: 99;
    appearance: none;
    border: none;
    cursor: initial !important;

    @media only screen and (max-width: 649px) {
        position: sticky;
        top: 80px; left: 100%;
    }
}

.stock {
    margin: 20px auto 5px;
}


.total {
    text-align: center;
    p:not(.saving-banner) {
        font-size: 1.3em;
        line-height: 1.4em;
        font-weight: 700;
        letter-spacing: 1px;
        margin: 0;
    }

    p.old-total {
        color: #666;
        text-decoration: line-through;
    }

    p.new-total {
        color: $blue;
        font-size: 1.5em;
        line-height: 1.7em;
        vertical-align: middle;
    }

    p.saving-banner {
        display: inline-block !important;
        background-color: $blue;
        color: #fff;
        vertical-align: middle;
        padding: 4px 7px;
        margin: 0;
    }
    p {
        -webkit-touch-callout: none;
            -webkit-user-select: none; 
             -khtml-user-select: none; 
               -moz-user-select: none; 
                -ms-user-select: none; 
                    user-select: none; 
    }
}

.donations-banner-pdp {
    padding: 17px 25px;
    margin-top: 15px;
    background-color: #fff;
    display: inline-block;
    p {
        margin: 0;
        color: $red;
        font-weight: 700;
    }

    @media only screen and (max-width: 649px) {
        text-align: left;
        margin-top: 0;
    }
}

.show-offers {
    p {
        background-color: $red;
        color: #fff;
        display: inline-block;
        padding: 2px 7px;
    }
}

.breadcrumb {
    background-color: transparent;
    padding-top: 7px;
    width: 100%;
    a {
        color: #222;
        text-decoration: underline;
        padding-left: 30px;
        position: relative;
        font-weight: 400;
        &:before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 2px;
            width: 30px;
            height: 20px;
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfkBAISEDaEUm2pAAABA0lEQVRo3u2ZSQrCQBBFPy7FwzisnEC8hGdx3jrdRvAAnkcFFdflIhoiLQYX+b8gXVkm5L1uQroGoLjo44Bage/Pxd9hOKoUErypFAZ4vPAGw4iN76WrNxjGEc+MLm4Z/KTU+GnJ8B1P+FnJ8G1cM/h5xOvwCza+hYsf/JKNb6rxZz/4lRa/ZuMbEZ9eGza+jpMf/DbidfhdEYjKz7vGXrFkD/5ToH8FUeG7Av1fCMhPg1CBfh46URDnRKECPSsMFeh5caIgrQwAeW0UKtCrQ0BeH4cK9A5BVHiHuE/mUoHeKwXk3WKXCvSJgRMF8dTIiYJ4cPm5C+LpsQwPAEPsUc176Amwp4ytHUb/LwAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMC0wNC0wMlQxODoxNjo1NCswMDowMNVn+ZgAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjAtMDQtMDJUMTg6MTY6NTQrMDA6MDCkOkEkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==');
            background-repeat: no-repeat;
            background-position: center left;
            background-size: contain;
        }
    }
}

.ProductTerms {
    border: 1px solid $blue;
    padding: 20px;
    p, div {
        font-size: 11px !important;
        margin-bottom: 7px !important;
    }

}

div.end-date {
    margin-bottom: 18px !important;
    p {
        display: inline-block;
        padding: 2px 9px !important;
    }
}

.winners-page .collections-list {
    .perticket, .btn-atb {
        display: none;
    }
}