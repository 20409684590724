@import '../var.scss';

.list-group-flush#profile {
    width: 100%;
}

div.profile {
    display: block !important;
    max-width: 95%;
    margin: auto;   

    #profile {
        // display: block;
        > a {
            flex: 1 1 0;
            display: inline-block;
            width: auto;
        }
    }
}

.signout {
    position: absolute;
    bottom: 0; left: 0; right: 0;
}

.comp-link {
    // color: $red !important;
}

.header-signin {
    // border-left: 1px solid $red;
    // padding: 8px 12px;
    // margin-left: 5px;
    // margin-top: 5px;
    display: block;
    margin: 3px 5px 0 12px;
    // border-bottom: 2px solid $red;
    // text-align: right !important;
    font-size: .9em;
    line-height: 1.4em;
    // text-transform: uppercase;
    
    sup {
        top: 0;
    }
}



@media only screen and (min-width: 849px) {
    div.profile {
        display: inline-block !important;
        width: auto;
        #profile {
            &.collapse {
                display: none !important;
            }
            > a {
                display: inline-block;
                width: 100%;
            }
        }
    }
    .header-wrap #menuToggle { display: none !important; }
    .header-wrap #navAccordion {
        text-align: center !important;
        border-right: none !important;
        display: flex !important;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        margin: auto;
        

        .list-group-item a {
            outline: none;
            &:focus {
                outline: none;
            }
        }

        .list-group-item.active {
            background-color: darken($color: #f8f9fa, $amount: .5);
        }

        .list-group-item {
            flex: 1;
            width: calc(100% / 8);
            display: inline-flex;
            justify-content: center;
        }

        a[data-target="#profile"] {
            width: 100%;
            display: block;
            & + div > a {
                padding: 10px 0 !important;
                display: block;
                width: 100%;
            }
        }
    }
    .header-cart {
        display: none !important;
    }
    .header-wrap .signout {
        display: none !important;
    }
    footer .signout {
        position: relative;
    }
}


footer {
    .col-md-12 > .list-group {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        text-align: left !important;
        margin: 0;
        max-width: 80%;
        .list-group-item, .account-nav, .signout {
            flex: 2;
            display: inline-block !important;
            width: auto;
            background-color: transparent;
            color: #222222 !important;
            text-decoration: underline;
            a.list-group-item {
                padding: .75rem 1.25rem !important;
            }
            a {
                background-color: transparent !important;
                color: #222222 !important;
                
            }
        }

        #navAccordion {
            padding-right: 20px;
        }

        a[data-target="#profile"] { display: none !important; }

        .list-group-flush#profile {
            position: static;
            width: auto;
            display: block !important;
            a.list-group-item {
                display: inline-block !important;
                width: auto;
                border: none !important;
            }
        }
    }
}


// Show header differently on cart and checkout
.secure-checkout {
    display: none;
}
.page-cart, .page-checkout {
    #navAccordion, #menuToggle { 
        display: none !important;
    }
    .header-wrap {
        text-align: left;
        padding: 0 20px;
    }
    .header-cart {
        width: auto;
        display: inline-block !important;
    }
    #headerNav {
        // width: calc(100% - 152px);
    }
    .secure-checkout {
        display: block;
        margin-top: 3px;
        padding-left: 10px;
        svg, p {
            display: inline-block;
            vertical-align: middle;
            margin: 0;
        }
        p {
            font-size: .85em;
            font-weight: 700;
        }

    }
    @media only screen and (max-width: 410px) {
        .secure-checkout p {
            display: none;
        }
        .header-cart {
            float: right;
            margin-top: 10px;
        }
    }
}

@media only screen and (max-width: 1099px) {
    .header-wrap .navigation {
        max-width: 100% !important;
    }
}