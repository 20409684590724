.table {
    overflow-x: scroll;
}

ol {
    padding-left: 0;
}

ul {
    padding-left: 0;
}