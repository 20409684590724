@import '../var.scss';

// Cart header styles
// .header-cart { display: none !important; }

table {
    text-align: left;
    td {
        padding: 0;
        text-align: left;
        a {
            width: 100%;
        }
        &:before {
            top: 10px !important;
            left: 10px !important;
            font-weight: 700;
        }
    }
    td[data-label="Product"], td[data-label="Action"] {
        &:before {
            display: none;
        }
    }
    td[data-label="Product"] {
        padding: 10px;
        text-align: center;
        @media only screen and (min-width: 879px) {
            max-width: 420px;
        }
        a {
            display: block;
            margin: auto !important;
        }
    }
    td[data-label="Action"] {
        button.btn {
            appearance: none;
            background-color: transparent;
            color: $red;
            border: none;
            padding-top: 0;
            text-decoration: underline;
        }
    }
    td[data-label="Cart Total"] {
        text-align: right;
    }
    .discount {
        font-size: .9em;
        color: $red;
        font-weight: 700;
        span {
            text-decoration: line-through;
            display: inline;
            font-weight: 400;
        }
    }
    .freedel-banner .navbar {
        border: 2px solid $blue;
        background-color: #fff;
        span {
            color: $blue;
        }
    }
}


.cart-hide {
    table.table:first-of-type tr {
        margin: 0;
    }
    table.table:first-of-type td, .freedel-banner {
        display: none !important;
    }
}



.back-link {
    font-weight: 700;
    margin: 0;
    text-align: left;
    &:hover, &:focus {
        color: $red;
    }
}


@media only screen and (max-width: 679px) {
    .cart table {
        .d-inline-block {
            display: block !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
            text-align: center;
        }
        td[data-label="Product"] {
            text-align: center !important;
        }
        td[data-label="Action"] {
            display: block !important;
            text-align: right;
        }
    }

}
.toggleCart {
    position: sticky;
    top: 60px;
    z-index: 9;
    background-color: #fff;

    svg {
        float: right;
        position: relative;
        right: -5px;
        top: 1px;
        transition: ease all .1s;
    }

    button {
        padding: 8px 2.5% !important;
        &:hover, &:focus {
            background-color: $blue;
            color: #fff
        }
    }

    button.btn-active svg {
        transform: rotate(-90deg);
        top: -4px;
        
    }
}


.add-ons {
    .add-on-prod {
        a {
            display: block;
            padding: 0 20px;
            margin-bottom: 15px;
        }
        img {
            margin: auto;
        }
    }
}