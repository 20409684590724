@import '../var.scss';

.scrollUp {
    appearance: none;
    position: fixed;
    bottom: 12%;
    right: 0;
    background-color: $blue;
    color: #fff;
    transition: ease all .2s;
    padding: 12px;
    z-index: 9;
    border: none;
    border-radius: 3px 0 0 3px;
    -webkit-box-shadow: -1px 5px 15px 0px #000000; 
    box-shadow: -1px 5px 15px 0px #000000;
    opacity: 0;
    visibility: hidden;

    &.show {
        opacity: 1;
        visibility: visible;
    }
}