@charset "UTF-8";
.details {
  text-align: left; }
  .details .card-body strong {
    min-width: 100px;
    display: inline-block; }
  .details label.terms {
    background-color: #e71837;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    display: block;
    color: #fff;
    position: relative;
    top: 0;
    left: 0;
    text-align: center;
    font-size: 1.4em;
    font-weight: 700; }
  .details input.terms-input {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    £top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
    background-color: transparent;
    border: none; }

a.underlink {
  text-decoration: underline; }

.checkout-total td {
  position: relative; }

.checkout-total td:before {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 55%;
  padding-right: 10px;
  white-space: nowrap;
  content: attr(data-label);
  text-align: left; }

.checkout-terms p {
  font-size: .9em;
  border: 1px solid #e71837;
  padding: 12px 10px; }

/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease; }

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df; }

.StripeElement--invalid {
  border-color: #fa755a; }

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important; }

.paypal-button-text {
  display: none; }

.checkout-form {
  position: relative; }
  .checkout-form .loading {
    transition: ease-in-out all .2s;
    background-color: #fff;
    position: absolute !important;
    padding: 20px 10px 10px !important; }
    .checkout-form .loading p {
      color: #222222;
      font-size: 16px; }
  .checkout-form button.btn {
    margin-top: 15px; }

.free-del-banner {
  border: 2px solid #27316c; }
  .free-del-banner span {
    color: #27316c !important; }

.paymentCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 320px;
  margin: auto; }
  .paymentCards img {
    flex-grow: 1;
    width: 45%;
    max-height: 40px; }

.secure h6 {
  vertical-align: bottom; }

@media only screen and (max-width: 679px) {
  td[data-label="Shipping"] {
    text-align: right; } }

.pp-err-message {
  display: inline-block;
  max-width: 740px;
  margin: 20px auto;
  border: 2px solid #e71837;
  padding: 10px; }
  .pp-err-message p:last-of-type {
    margin-bottom: 0;
    font-weight: 700; }

.StripeElement {
  border-bottom: 2px solid #27316c; }

.stripe-error {
  background-color: #e71837;
  padding: 15px; }
  .stripe-error p {
    color: #fff; }

@media only screen and (max-width: 749px) {
  .page-checkout table td[data-label="Product"] {
    width: 100%; } }

.payment-loading {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.7);
  color: #222222;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  text-align: center; }
  .payment-loading p {
    font-size: 2em;
    font-weight: 700;
    display: block;
    max-width: 740px; }
