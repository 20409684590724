@import '../var';

.product-qty {
    text-align: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
    button {
        flex: 1;
        appearance: none;
        margin: 20px 5px;
        border: 2px solid $red;
        border-radius: 3px;
        position: relative;
        padding: 6px 20px;
        font-size: 1.3em;
        line-height: 1.32em;
        font-weight: 700;
        outline: none;
        background-color: #fff;
        color: $red;
        text-transform: lowercase !important;
        transition: ease-in-out all .2s;

        &:focus {
            outline: none;
        }

        &.qty-active {
            background-color: $red;
            color: #fff;
        }

        sup {
            position: absolute;
            bottom: 100%;
            right: 2px;
            color: #fff;
            text-transform: capitalize;
            background-color: $red;
            height: 20px;
            top: auto;
            font-size: 11px;
            line-height: 12px;
            padding: 4px 6px;
            border-radius: 3px 3px 0 0;
        }

        span {
            display: block;
            clear: both;
            // position: absolute;
            // bottom: 0; left: 0; right: 0;
            width: 100%;
            font-size: .8em;
            white-space: nowrap;
            pointer-events: none;
        }

        &.btn-topQty {
            
            text-transform: capitalize;
            border-width: 2px;
            
            
            sup {
                padding: 6px;
                font-size: 13px;
                line-height: 15px;
                height: auto;
            }
        }

        &.btn-topQty, &.btn-lessXPad {
            flex: 1.5; 
            padding: 6px 15px !important;
            border-color: #e8b923;
            color: #e8b923;
            sup {
                background-color: #e8b923;
                color: #fff;
            }

            &.qty-active {
                background-color: #e8b923;
                color: #fff;
            }
        }
        &.btn-lessXPad {
            flex-basis: 100px;
        }
    }
}

.quantity-new {
    .discount-badge {
        p {
            display: inline-block;
            background-color: transparent;
            // width: 100%;
            padding: 12px 25px !important;
            margin: 12px auto 8px auto;
            border: 2px solid transparent;
            -webkit-transition: ease-in-out all .2s;
            transition: ease-in-out all .2s;
            outline: none;
            font-weight: 700;
            text-transform: uppercase;
            border: 2px solid #e8b923;
            margin-bottom: 0;
            color: #e8b923 !important;
            letter-spacing: .05em;
            -webkit-touch-callout: none;
            -webkit-user-select: none; 
             -khtml-user-select: none; 
               -moz-user-select: none; 
                -ms-user-select: none; 
                    user-select: none; 
        }
    }
    .number {
        span {
            // font-size: 1.4em;
            padding: 10px;
            background-color: #f1f1f1;
            width: 45px;
            display: inline-block;
            transition: ease-in-out all .2s;
            cursor: pointer;
            text-align: center;
            border: 1px solid #fff;
            border-radius: 2px;
        }
        span.plus {
            border-left: none;
            &:hover, &:focus {
                background-color: darken($green, $amount: 15);
                svg {
                    fill: #fff;
                }
            }
        }
        span.minus {
            border-right: none;
            &:hover, &:focus {
                background-color: $red;
                svg {
                    fill: #fff;
                }
            }
        }
    }
    input[type="number"] {
        padding: 15px 30px;
        text-align: center;
        font-size: 1.4em;
        font-weight: 700;
        color: $red;
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }
}